import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import Joi from 'joi';
import Input from '@components/common/atoms/Input';
import { joiResolver } from '@hookform/resolvers/joi';
import { Ambita as AmbitaModel } from '@supertakst/model-common';
import AddressLookup from '@components/CreateModal/Matrikkel/Ambita/AddressLookup';
import AddressSelected from '@components/CreateModal/Matrikkel/Ambita/AddressSelected';

type Props = {
  onSave: (data) => void;
};

type FormData = {
  isAmbita: boolean;
  ambita:
    | {
        address: AmbitaModel.Address;
        cadastre?: AmbitaModel.Cadastre & { owners: Array<{ name: string }> };
        share?: AmbitaModel.Share & { owners: Array<{ name: string }> };
      }
    | undefined;
};

const Ambita = ({ onSave }: Props) => {
  const validationSchema = Joi.object({
    isAmbita: Joi.boolean().required(),
    ambita: Joi.object({
      address: Joi.object().required(),
      cadastre: Joi.object(),
      share: Joi.object(),
    }),
  });

  const methods = useForm<FormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  const ambita = useWatch({ control: methods.control, name: 'ambita' });

  const onUnitSelect = (data) => {
    methods.setValue('ambita', data);
  };

  const onClearSelectedUnit = () => {
    methods.setValue('ambita', undefined);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) => onSave(data))}>
          <Input id="isAmbita" type="hidden" value="true" />
          {ambita ? (
            <AddressSelected ambita={ambita} onClearSelectedUnit={onClearSelectedUnit} />
          ) : (
            <AddressLookup onUnitSelect={onUnitSelect} />
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default Ambita;
