import React, { Suspense, useState } from 'react';
import { useDebounce } from 'react-use';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import ListLoader from '@components/common/ListLoader';
import ErrorInline from 'components/common/Error/ErrorInline';
import List from '@components/CreateModal/Matrikkel/Ambita/AddressLookup/List';

type Props = {
  onUnitSelect: (data) => void;
};

const AddressLookup = ({ onUnitSelect }: Props) => {
  const [address, setAddress] = useState<string>('');
  const [debouncedAddress, setDebouncedAddress] = useState<string>('');
  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);
  const { reset } = useQueryErrorResetBoundary();

  const isSelected = (key) => selectedAddresses.includes(key);

  const addAddressToSelected = (address) => {
    if (isSelected(address)) {
      setSelectedAddresses(selectedAddresses.filter((a) => a !== address));
    } else {
      setSelectedAddresses([...selectedAddresses, address]);
    }
  };

  useDebounce(
    () => {
      setDebouncedAddress(address);
    },
    500,
    [address]
  );

  const onSetAddress = (addressString: string) => {
    setAddress(addressString);
    setSelectedAddresses([]);
  };

  return (
    <>
      <input
        className="p-6 appearance-none border border-[#E9ECED] rounded w-full text-base bg-[#F6F9FB] text-supertakst-blue-900 leading-tight text-center focus:outline-none focus:shadow-outline"
        type="text"
        placeholder="Søk etter adresse&hellip;"
        defaultValue={address}
        onChange={({ currentTarget }) => onSetAddress(currentTarget.value)}
      />
      <ErrorBoundary
        onReset={reset}
        fallbackRender={(props) => (
          <ErrorInline
            {...props}
            message={`Noe gikk galt, kunne ikke hente adresser for dette søket`}
          />
        )}
      >
        <Suspense fallback={<ListLoader as="span" count={2} height={56} />}>
          <List
            searchAddress={debouncedAddress}
            isSelected={isSelected}
            onSelect={addAddressToSelected}
            onUnitSelect={onUnitSelect}
          />
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default AddressLookup;
