import React, { useEffect } from 'react';
import Joi from 'joi';
import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from '@supertakst/ui-common';
import { Matrikkel } from '@supertakst/model-common';
import {
  MatrikkelForm,
  MatrikkelSchema,
} from '@components/StandaloneParts/GenerellInformasjon/v1/Matrikkel/MatrikkelForm';
import Input from '@components/common/atoms/Input';

type Props = {
  onSave: (data) => void;
};

type FormData = {
  isAmbita: boolean;
  manual: Matrikkel;
};

const Form = ({ onSave }: Props) => {
  const validationSchema = Joi.object({
    isAmbita: Joi.boolean().required(),
    manual: MatrikkelSchema,
  });

  const methods = useForm<FormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  useEffect(() => {
    (async () => {
      await methods.trigger();
    })();
  }, [methods]);

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <Input id="isAmbita" type="hidden" value="false" />
          <MatrikkelForm id="manual" />
          <Button
            type="submit"
            className="mt-8 mx-auto block"
            disabled={!methods.formState.isValid}
          >
            Neste
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;
