import { formatDate } from '@utils/date';
import Link from 'next/link';
import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon, ExclamationIcon } from '@supertakst/ui-common';
import ConfirmDialog from '@components/common/ConfirmDialog';
import {
  useArchiveTilstandsrapport,
  useDuplicateTilstandsrapport,
} from '@components/hooks/tilstandsrapportQueries';
import { toast } from 'react-toastify';
import { BoligType, modelUtils, Tilstandsrapport } from '@supertakst/model-common';
import { getBefaringProgress } from '@utils/validations';
import { twMerge } from 'tailwind-merge';

type Props = {
  tilstandsrapport: Tilstandsrapport;
  isNew: boolean;
  markAsNew: (id: number) => void;
};

const ReportListItem = ({ tilstandsrapport, isNew, markAsNew }: Props) => {
  const [confirmDeleteReport, setConfirmDeleteReport] = useState<number | undefined>();
  const progress = getBefaringProgress(tilstandsrapport);
  const isDone = progress === 100;
  const address = modelUtils.getAddressWithMattrikkelInfo(tilstandsrapport);
  const boligType =
    tilstandsrapport.generellInformasjon.boligType === BoligType.BOLIG ? 'Bolig' : 'Leilighet';

  const { mutate: doArchiveTilstandsrapport } = useArchiveTilstandsrapport(
    tilstandsrapport.id,
    () => toast.success('Tilstandsrapport slettet'),
    () => toast.error('Det skjedde en feil')
  );

  const { mutate: doDuplicateTilstandsrapport, data: duplicatedReport } =
    useDuplicateTilstandsrapport(
      tilstandsrapport.id,
      () => toast.success('Tilstandsrapport duplisert'),
      () => toast.error('Det skjedde en feil')
    );

  useEffect(() => {
    if (duplicatedReport) {
      markAsNew(duplicatedReport.id);
    }
  }, [duplicatedReport]);

  const duplicateTilstandsrapport = (data: Tilstandsrapport) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tmpid = -new Date();
    const dulicatedReportData = {
      ...data,
      id: tmpid,
      updatedAt: new Date(),
      generellInformasjon: {
        ...data.generellInformasjon,
        matrikkel: {
          ...data.generellInformasjon.matrikkel,
          adresse: `${data.generellInformasjon.matrikkel?.adresse} (kopi)`,
        },
      },
    };
    doDuplicateTilstandsrapport(dulicatedReportData);
  };

  const convertToApartmentOrBolig = (data: Tilstandsrapport) => {
    const originalType = data.generellInformasjon.boligType;
    const targetType = originalType === BoligType.BOLIG ? BoligType.LEILIGHET : BoligType.BOLIG;
    const tmpid = -new Date();

    let convertedReportData = {
      ...data,
      id: tmpid,
      updatedAt: new Date(),
      generellInformasjon: {
        ...data.generellInformasjon,
        boligType: targetType,
        matrikkel: {
          ...data.generellInformasjon.matrikkel,
          adresse: `${data.generellInformasjon.matrikkel?.adresse} (konvertert fra ${
            originalType === BoligType.BOLIG ? 'bolig' : 'leilighet'
          })`,
        },
      },
    };

    const parts = modelUtils.generatePartsFromBoligType(targetType, data.modelVersion);

    const existingParts = data.parts.filter(
      (part) => parts.find((p) => p.partType === part.partType) !== undefined
    );

    const newParts = parts.filter(
      (part) => data.parts.find((p) => p.partType === part.partType) === undefined
    );

    convertedReportData.parts = [...existingParts, ...newParts];
    doDuplicateTilstandsrapport(convertedReportData);
  };

  return (
    <>
      <div>
        <div
          className={twMerge(
            'border-neutral-light-contrast border-solid border bg-white rounded pl-5 block flex items-center h-20',
            isNew ? 'bg-supertakst-blue-50 border-supertakst-blue-300' : null
          )}
        >
          <Link href={`/tilstandsrapport/${tilstandsrapport.modelVersion}/${tilstandsrapport.id}`}>
            <a className="flex flex-1 flex-col">
              <span className="text-xs text-neutral-dark-contrast/50 line-clamp-1">
                {`${boligType}, sist oppdatert ${formatDate(
                  tilstandsrapport.updatedAt,
                  'EEEE dd.MM.yyyy H:mm'
                )}`}
                <span>
                  {' '}
                  &mdash; {tilstandsrapport.id}
                  {tilstandsrapport.supertakstId
                    ? ' Tilhører Supertakst ordre #' + tilstandsrapport.supertakstId
                    : null}
                </span>
              </span>
              <span className="line-clamp-1 h3Medium text-supertakst-blue-900">{address}</span>
            </a>
          </Link>
          <div className="flex items-center self-stretch px-5">
            <div
              className={classnames(
                'border border-supertakst-green-300 rounded-full w-[75px] text-center smallText py-3',
                isDone
                  ? 'bg-supertakst-green-300 text-white'
                  : 'bg-[#DCFBEA] text-supertakst-blue-900'
              )}
            >
              {progress}%
            </div>
          </div>
          <div className="flex items-center self-stretch border-l border-neutral-light-contrast px-7">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="shrink-0 border-neutral-light-constrast w-8 aspect-square border border-solid rounded-full flex items-center justify-center">
                  <span className="sr-only">Åpne valg</span>
                  <DotsHorizontalIcon className="scale-75" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <div className="px-4 py-3">
                      <p className="text-sm font-medium text-gray-900">Valg</p>
                    </div>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => duplicateTilstandsrapport(tilstandsrapport)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'text-left w-full block px-4 py-2 text-sm'
                          )}
                        >
                          Dupliser
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => convertToApartmentOrBolig(tilstandsrapport)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'text-left w-full block px-4 py-2 text-sm'
                          )}
                        >
                          {`Konverter til ${
                            tilstandsrapport.generellInformasjon.boligType === BoligType.BOLIG
                              ? 'leilighet'
                              : 'bolig'
                          }`}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setConfirmDeleteReport(tilstandsrapport.id)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'text-left w-full block px-4 py-2 text-sm'
                          )}
                        >
                          Slett
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      {confirmDeleteReport && (
        <ConfirmDialog
          icon={ExclamationIcon}
          header="Slett tilstandsrapport"
          text={`Er du sikker på at du vil slette tilstandsrapporten "${address}"? Dette kan ikke angres.
          ${
            tilstandsrapport.supertakstId
              ? `NB! Denne rapporten tilhører Supertakst ordre #${tilstandsrapport.supertakstId}. Er du helt sikker?`
              : ''
          }`}
          onCancel={() => setConfirmDeleteReport(undefined)}
          onConfirm={() => {
            setConfirmDeleteReport(undefined);
            doArchiveTilstandsrapport();
          }}
        />
      )}
    </>
  );
};

export default ReportListItem;
