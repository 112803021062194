import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';
import { readableAddress } from '@utils/ambita';
import { getOwners } from '@api/ambita';
import { useAddressLookup } from '@components/hooks/ambitaQueries';
import Units from '@components/CreateModal/Matrikkel/Ambita/AddressLookup/Units';
import ListLoader from '@components/common/ListLoader';
import ErrorInline from 'components/common/Error/ErrorInline';

type Props = {
  searchAddress: string;
  isSelected: (addressKey: string) => boolean;
  onSelect: (addressKey: string) => void;
  onUnitSelect: (data) => void;
};

const List = ({ searchAddress, isSelected, onSelect, onUnitSelect }: Props) => {
  const { reset } = useQueryErrorResetBoundary();

  const unitSelected = async (address, unit) => {
    const isShareUnit = unit.ident?.shareNumber || false;
    try {
      unit.owners = await getOwners(
        isShareUnit ? { shareId: unit.key } : { cadastreKey: unit.key }
      );
    } catch (e) {
      // silent catch as app works without prefilled hjemmelshavere
    }
    onUnitSelect({ address, ...(isShareUnit ? { share: unit } : { cadastre: unit }) });
  };

  const { data } = useAddressLookup(searchAddress, {
    enabled: searchAddress.length > 3,
    suspense: true,
  });

  return (
    <ul className="list-none mt-6">
      {data?.map((address) => {
        const selected = isSelected(address.key);
        return (
          <li className="odd:bg-[#F6F9FB]" key={address.key}>
            <button
              type="button"
              onClick={() => onSelect(address.key)}
              className={twMerge(
                'py-5 px-9 w-full text-xs font-medium text-left',
                selected ? 'bg-[#D8F7E6]' : null
              )}
            >
              {readableAddress(address)}
            </button>
            {selected && (
              <ErrorBoundary
                onReset={reset}
                fallbackRender={(props) => (
                  <ErrorInline
                    {...props}
                    message={`Noe gikk galt, kunne ikke hente ut enheter for denne adressen`}
                  />
                )}
              >
                <Suspense fallback={<ListLoader as="span" count={2} height={56} />}>
                  <Units address={address} selected unitSelected={unitSelected} />
                </Suspense>
              </ErrorBoundary>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default List;
