import React from 'react';
import { Button } from '@supertakst/ui-common';
import { twMerge } from 'tailwind-merge';

const ErrorInline = ({ resetErrorBoundary, message, className = '' }) => {
  // use error.message instead of custom message?
  return (
    <div className={twMerge('my-2', className)}>
      <p className="text-[#FB4242]">{message}</p>
      <Button buttonType="tertiary" onClick={resetErrorBoundary} className="mt-2">
        Prøv igjen
      </Button>
    </div>
  );
};

export default ErrorInline;
