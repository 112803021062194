import { addressLookup, getUnitsForAddress, UnitsApiResponse } from '@api/ambita';
import { useQuery } from '@tanstack/react-query';

export const ambitaQueryKeys = {
  address: (input) => ['address', input],
  units: (key) => ['units', key],
};

export function useUnitsForAddress(addressKey: string, options = {}) {
  return useQuery<UnitsApiResponse>(
    ambitaQueryKeys.units(addressKey),
    async () => {
      return await getUnitsForAddress(addressKey);
    },
    options
  );
}

export function useAddressLookup(q: string, options = {}) {
  return useQuery(
    ambitaQueryKeys.address(q),
    async () => {
      return await addressLookup(q);
    },
    options
  );
}
