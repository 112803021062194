import React, { useState } from 'react';
import { Button } from '@supertakst/ui-common';
import SearchBar from './SearchBar';
import { Portal } from 'react-portal';
import CreateModal from '@components/CreateModal';
import { useKey } from 'react-use';

type Props = {
  onSearch: (searchString: string) => void;
};

const SearchCreate = ({ onSearch }: Props) => {
  const [createOpen, setCreateOpen] = useState<boolean>(false);

  useKey('Escape', () => setCreateOpen(false));

  return (
    <div className="flex items-start gap-4">
      <div className="flex-1">
        <SearchBar onSearch={onSearch} />
      </div>
      <div className="ml-auto mb-auto">
        <Button onClick={() => setCreateOpen(true)}>+ Opprett</Button>
      </div>
      {createOpen && (
        <Portal>
          <CreateModal onClose={() => setCreateOpen(false)} isOpen={createOpen} />
        </Portal>
      )}
    </div>
  );
};

export default SearchCreate;
