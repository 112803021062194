import React from 'react';
import Datepicker from '@components/common/atoms/Datepicker';
import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Button } from '@supertakst/ui-common';

type Props = {
  onSave: (data) => void;
  isSubmitting: boolean;
};

type FormData = {
  befaringsDato: string;
};

const Date = ({ onSave, isSubmitting }: Props) => {
  const validationSchema = Joi.object({
    befaringsDato: { value: Joi.date() },
  });
  const methods = useForm<FormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  const { isValid } = methods.formState;

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <h2 className="h2 text-center text-supertakst-blue-900">Befaringsdato</h2>
          <div className="flex lg:justify-center">
            <div className="w-full lg:w-2/3 xl:w-1/2">
              <Datepicker id="befaringsDato" inline />
            </div>
          </div>
          <div className="mt-auto flex justify-center">
            <div>
              <Button type="submit" disabled={!isValid || isSubmitting}>
                Neste
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Date;
