import React, { useState } from 'react';
import { Button } from '@supertakst/ui-common';
import Form from '@components/CreateModal/Matrikkel/Form';
import Ambita from '@components/CreateModal/Matrikkel/Ambita';

type Props = {
  onSave: (data) => void;
};
const Matrikkel = ({ onSave }: Props) => {
  const [isManual, setIsManual] = useState<boolean>(false);

  const saveManual = (data) => {
    onSave({ ...data, ambita: undefined });
  };

  const saveAmbita = (data) => {
    onSave({ ...data, manual: undefined });
  };

  return (
    <div>
      <h2 className="h2 text-center text-supertakst-blue-900">Legg til adresse for befaring</h2>
      {isManual ? (
        <div className="pt-16">
          <Form onSave={saveManual} />
          <Button
            className="mt-8 block mx-auto"
            buttonType="tertiary"
            onClick={() => setIsManual(false)}
          >
            Søk i Ambita
          </Button>
        </div>
      ) : (
        <div className="pt-16">
          <Ambita onSave={saveAmbita} />
          <Button
            className="mt-8 block mx-auto"
            buttonType="tertiary"
            onClick={() => setIsManual(true)}
          >
            Legg til manuelt
          </Button>
        </div>
      )}
    </div>
  );
};

export default Matrikkel;
