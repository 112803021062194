import { SearchIcon, XIcon } from '@supertakst/ui-common';
import React from 'react';
import { useState } from 'react';

type Props = {
  onSearch: (searchString: string) => void;
};

const SearchBar = ({ onSearch }: Props) => {
  const [search, setSearch] = useState<string>('');

  const handleSearch = (value) => {
    setSearch(value);
    onSearch(value);
  };

  return (
    <div className="relative">
      <input
        type="text"
        className="w-full p-4 rounded border-[#EAEAEA]"
        onChange={(e) => handleSearch(e.target.value)}
        value={search}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        {search !== '' ? (
          <XIcon
            className="h-5 w-5 text-gray-400 cursor-pointer"
            onClick={() => handleSearch('')}
          />
        ) : (
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
