import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ReportListItem from '@components/common/ReportListItem';
import { useCompletedTilstandsrapporter } from '@components/hooks/tilstandsrapportQueries';

const Completed = () => {
  const { data } = useCompletedTilstandsrapporter({ suspense: true });
  const [newTilstandsrapportId, setNewTilstandsrapportId] = useState<number>();

  if (data?.length === 0) {
    return <div>Du har ingen gjennomførte befaringer.</div>;
  }
  return (
    <ul className="space-y-2.5">
      {data?.map((tilstandsrapport) => {
        return (
          <motion.li key={tilstandsrapport.id} layout>
            <ReportListItem
              tilstandsrapport={tilstandsrapport}
              isNew={newTilstandsrapportId === tilstandsrapport.id}
              markAsNew={(id) => setNewTilstandsrapportId(id)}
            />
          </motion.li>
        );
      })}
    </ul>
  );
};

export default Completed;
