import React from 'react';
import styles from './styles.module.scss';

type TypeButtonProps = {
  register: any;
  icon: React.ReactNode;
  value: string;
  text: string;
};

const TypeButton = ({ register, icon, text, value }: TypeButtonProps) => {
  return (
    <div className="flex flex-col">
      <label className={styles.typePicker}>
        <input type="radio" value={value} {...register('type', { required: true })} />
        <div className={styles.iconContainer}>{icon}</div>
      </label>
      <span className="text-center inline-block text-base font-medium">{text}</span>
    </div>
  );
};

export default TypeButton;
