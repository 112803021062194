import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={60} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#a)"
      stroke="#020C56"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m30 5 29 29V9H40M29.99 5 1 34V9h12M25 55V35h10v20M9 47h12M11 41h8M55 53.15V36M25 59h24M21 59v-4h18M5 36v17.15M30 23a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
      <path
        d="M15 55a4 4 0 1 0-8 0 3.19 3.19 0 0 0-3-2 3 3 0 0 0 0 6h11a2 2 0 0 0 0-4ZM45 55a4 4 0 1 1 8 0 3.19 3.19 0 0 1 3-2 3 3 0 0 1 0 6H45a2 2 0 0 1 0-4Z"
        fill="#25D275"
      />
      <path d="M13 9V5h-2V1h8v4h-2v4h3M19 47V35h-8v12M39 47h12M41 41h8M49 47V35h-8v12M33 45h2" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h60v60H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
