import { Suspense, useState } from 'react';
import { useDebounce } from 'react-use';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { LayoutGroup } from 'framer-motion';
import PageContainer from '@components/common/PageContainer';
import SearchCreate from '@components/containers/HomeContainer/SearchCreate';
import ListLoader from '@components/common/ListLoader';
import { useSystemPhrases, useUserPhrases } from '@components/hooks/phrasesQueries';
import { useTooltips } from '@components/hooks/tooltipQueries';
import Header from '@components/common/Header';
import OnGoing from './OnGoing';
import Search from './Search';
import Completed from './Completed';
import ErrorInline from 'components/common/Error/ErrorInline';

const Home = () => {
  const { user } = useAuth0();
  const [searchValue, setSearchValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const { reset } = useQueryErrorResetBoundary();

  useUserPhrases();
  useSystemPhrases();
  useTooltips();

  useDebounce(
    () => {
      setSearch(searchValue);
    },
    500,
    [searchValue]
  );

  const onSearch = (searchString) => {
    setSearchValue(searchString);
  };

  return (
    <>
      <PageContainer>
        <main>
          {user && (
            <div className="space-y-12 py-8">
              <SearchCreate onSearch={onSearch} />
              <section>
                <Header as="h3" className="pb-6">
                  Siste påbegynte befaringer
                </Header>
                <LayoutGroup>
                  <ErrorBoundary
                    onReset={reset}
                    fallbackRender={(props) => (
                      <ErrorInline
                        {...props}
                        message={`Noe gikk galt, kunne ikke ${
                          search.length > 3 ? 'søke på' : 'liste ut'
                        } påbegynte befaringer`}
                      />
                    )}
                  >
                    <Suspense fallback={<ListLoader as="div" />}>
                      {search.length > 3 ? (
                        <Search searchParam={search} submitted={false} />
                      ) : (
                        <OnGoing />
                      )}
                    </Suspense>
                  </ErrorBoundary>
                </LayoutGroup>
              </section>
              <section>
                <Header as="h3" className="pb-6">
                  Gjennomførte oppdrag
                </Header>

                <LayoutGroup>
                  <ErrorBoundary
                    onReset={reset}
                    fallbackRender={(props) => (
                      <ErrorInline
                        {...props}
                        message={`Noe gikk galt, kunne ikke ${
                          search.length > 3 ? 'søke på' : 'liste ut'
                        } gjennomførte oppdrag`}
                      />
                    )}
                  >
                    <Suspense fallback={<ListLoader as="div" />}>
                      {search.length > 3 ? (
                        <Search searchParam={search} submitted={true} />
                      ) : (
                        <Completed />
                      )}
                    </Suspense>
                  </ErrorBoundary>
                </LayoutGroup>
              </section>
            </div>
          )}
        </main>
      </PageContainer>
    </>
  );
};

export default Home;
