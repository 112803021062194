import Modal from '@components/common/Modal';
import React, { useState } from 'react';
import { Button } from '@supertakst/ui-common';
import { useRouter } from 'next/router';
import { createNewTilstandsrapport } from '@api/tilstandsrapport';
import { Ambita, BoligType } from '@supertakst/model-common';

import Type from './Type';
import Matrikkel from './Matrikkel';
import Date from './Date';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

type WizardData =
  | {
      type: BoligType;
      ambita?: { address: Ambita.Address; cadastre?: Ambita.Cadastre; share?: Ambita.Share };
      manual?: unknown;
      befaringsDato?: { value?: Date };
    }
  | {};

const CreateModal = ({ onClose, isOpen }: Props) => {
  const router = useRouter();

  const [step, setStep] = useState<number>(0);
  const [data, setData] = useState<WizardData>({});
  const [isSubmitting, SetIsSubmittinhg] = useState<boolean>(false);

  const doOnSave = (values) => {
    setData({ ...data, ...values });
    setStep(step + 1);
  };

  const saveAndCreate = async (values) => {
    SetIsSubmittinhg(true);
    try {
      const newTilstandsrapport = await createNewTilstandsrapport({ ...data, ...values });
      router.push(
        `/tilstandsrapport/${newTilstandsrapport.modelVersion}/${newTilstandsrapport.id}`
      );
    } catch (err) {
      console.error(err);
    }
    SetIsSubmittinhg(false);
  };

  const renderFormStep = () => {
    switch (step) {
      case 0:
        return <Type onSave={(values) => doOnSave(values)} />;
      case 1:
        return <Matrikkel onSave={(values) => doOnSave(values)} />;
      case 2:
        return <Date onSave={(values) => saveAndCreate(values)} isSubmitting={isSubmitting} />;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="max-h-[75vh] overflow-y-auto modal">
        {renderFormStep()}
        <div className="flex justify-center mt-4">
          <Button buttonType="tertiary" onClick={onClose}>
            Avbryt
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateModal;
