import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={60} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#a)"
      stroke="#020C56"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 53V1h26v52M15 59v-2h28M1 33V13h12M59 57V13H47M7 33v-2h4v6" />
      <path d="M53 31h-4v6h4v-6ZM53 19h-4v6h4v-6ZM11 19H7v6h4v-6ZM53 43h-4v6h4v-6ZM37 31h-4v6h4v-6ZM37 19h-4v6h4v-6ZM27 31h-4v6h4v-6ZM27 19h-4v6h4v-6ZM37 7h-4v6h4V7ZM27 7h-4v6h4V7ZM35 53V43M25 53V43" />
      <path
        d="M57 55a2 2 0 0 0-1.08.32 3 3 0 0 0-5.84 0A2 2 0 1 0 49 59h8a2 2 0 0 0 0-4ZM9 49c0-4-1-12-4-12s-4 8-4 12a4 4 0 1 0 8 0Z"
        fill="#25D275"
      />
      <path d="M5 53v6M21 43h18" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h60v60H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
