import React from 'react';
import { readableAddress } from '@utils/ambita';
import { Button } from '@supertakst/ui-common';
import { Ambita as AmbitaModel } from '@supertakst/model-common';

type Props = {
  ambita:
    | {
        address: AmbitaModel.Address;
        cadastre?: AmbitaModel.Cadastre & { owners: Array<{ name: string }> };
        share?: AmbitaModel.Share & { owners: Array<{ name: string }> };
      }
    | undefined;
  onClearSelectedUnit: () => void;
};

const AddressSelected = ({ ambita, onClearSelectedUnit }: Props) => {
  const getUnitDisplay = () => {
    if (ambita?.cadastre) {
      return `${readableAddress(ambita.address)} (${ambita.cadastre.key})`;
    } else if (ambita?.share) {
      return `${readableAddress(ambita.address)} (${ambita.share.landLot?.key}-${
        ambita.share.ident.shareNumber
      })`;
    }
  };

  return (
    <>
      <div onClick={onClearSelectedUnit}>
        <input
          className="p-6 appearance-none border border-[#E9ECED] rounded w-full text-base bg-[#F6F9FB] text-supertakst-blue-900 leading-tight text-center focus:outline-none focus:shadow-outline"
          type="text"
          readOnly
          value={getUnitDisplay()}
        />
      </div>
      <Button type="submit" className="mt-8 block mx-auto">
        Neste
      </Button>
    </>
  );
};

export default AddressSelected;
