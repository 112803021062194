import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ReportListItem from '@components/common/ReportListItem';
import { useTilstandsrapportSearch } from '@components/hooks/tilstandsrapportQueries';

type Props = {
  searchParam: string;
  submitted: boolean;
};

const Search = ({ searchParam = '', submitted }: Props) => {
  const { data } = useTilstandsrapportSearch(searchParam, submitted, {
    enabled: searchParam.length > 3,
    suspense: true,
  });
  const [newTilstandsrapportId, setNewTilstandsrapportId] = useState<number>();
  if (data?.length === 0) {
    return <div>Ingen befaringer funnet ved søk på &laquo;{searchParam}&raquo;.</div>;
  }
  return (
    <ul className="space-y-2.5">
      {data?.map((tilstandsrapport) => {
        return (
          <motion.li key={tilstandsrapport.id} layout>
            <ReportListItem
              tilstandsrapport={tilstandsrapport}
              isNew={newTilstandsrapportId === tilstandsrapport.id}
              markAsNew={(id) => setNewTilstandsrapportId(id)}
            />
          </motion.li>
        );
      })}
    </ul>
  );
};

export default Search;
