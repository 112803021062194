import type { NextPage } from 'next';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import HomeContainer from '@components/containers/HomeContainer';

const Home: NextPage = () => {
  return <HomeContainer />;
};

export default withAuthenticationRequired(Home, {
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
