import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ReportListItem from '@components/common/ReportListItem';
import { useOngoingTilstandsrapporter } from '@components/hooks/tilstandsrapportQueries';

const OnGoing = () => {
  const { data } = useOngoingTilstandsrapporter({ suspense: true });
  const [newTilstandsrapportId, setNewTilstandsrapportId] = useState<number>();

  if (data?.tilstandsrapporter.length === 0) {
    return (
      <div>Du har ingen påbegynte befaringer. Trykk &quot;Opprett&quot; for å lage en rapport.</div>
    );
  }

  return (
    <ul className="space-y-2.5">
      {data?.tilstandsrapporter
        .filter((t) => !t.lastSubmittedAt)
        .map((tilstandsrapport) => {
          return (
            <motion.li key={tilstandsrapport.id} layout>
              <ReportListItem
                tilstandsrapport={tilstandsrapport}
                isNew={newTilstandsrapportId === tilstandsrapport.id}
                markAsNew={(id) => setNewTilstandsrapportId(id)}
              />
            </motion.li>
          );
        })}
    </ul>
  );
};

export default OnGoing;
