import React from 'react';
import { useUnitsForAddress } from '@components/hooks/ambitaQueries';
import { Ambita } from '@supertakst/model-common';
type Props = {
  address: Ambita.Address;
  selected: boolean;
  unitSelected: (address: Ambita.Address, unit: Ambita.Cadastre | Ambita.Share) => void;
};
const Units = ({ address, selected, unitSelected }: Props) => {
  const { data } = useUnitsForAddress(address.key, { enabled: selected, suspense: true });

  return (
    <div className="ml-10">
      <>
        {data && data.cadastres?.length > 0 && (
          <ul className="bg-white">
            {data.cadastres.map((cadastre) => (
              <li
                onClick={() => unitSelected(address, cadastre)}
                key={cadastre.key}
                className="py-5 px-9 even:bg-white odd:bg-[#F6F9FB] text-xs cursor-pointer"
              >
                {cadastre.key}
              </li>
            ))}
          </ul>
        )}
        {data && data.shares?.length > 0 && (
          <ul className="bg-white">
            {data.shares.map((share) => (
              <li
                onClick={() => unitSelected(address, share)}
                key={share.key}
                className="py-5 px-9 even:bg-white odd:bg-[#F6F9FB] text-xs cursor-pointer"
              >
                {share.landLot?.key}, Andel: {share.ident.shareNumber}
              </li>
            ))}
          </ul>
        )}
      </>
    </div>
  );
};

export default Units;
