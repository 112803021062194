import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@supertakst/ui-common';

import House from './House';
import Apartment from './Apartment';
import TypeButton from './TypeButton';
import { BoligType } from '@supertakst/model-common';

type Props = {
  onSave: (data) => void;
};

type FormData = {
  type: BoligType;
};

const Type = ({ onSave }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>({ mode: 'onChange' });

  const onSubmit = handleSubmit((data) => onSave(data));
  return (
    <div>
      <h2 className="h2 text-center text-supertakst-blue-900">Velg boligtype for befaring</h2>
      <form onSubmit={onSubmit} className="flex flex-col">
        <div className="flex justify-center space-x-8 py-16">
          <TypeButton register={register} icon={<House />} text="Bolig" value={BoligType.BOLIG} />
          <TypeButton
            register={register}
            icon={<Apartment />}
            text="Leilighet"
            value={BoligType.LEILIGHET}
          />
        </div>
        <div className="mt-auto flex justify-center">
          <div>
            <Button type="submit" disabled={!isValid}>
              Neste
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Type;
