import axios from 'axios';
import { Ambita } from '@supertakst/model-common';
import * as auth0Utils from '@utils/auth0';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 20000,
});
client.interceptors.request.use(async (reqConfig) => {
  const accessToken = await auth0Utils.getTokenSilently();
  return {
    ...reqConfig,
    headers: {
      ...reqConfig.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
});

export type UnitsApiResponse = {
  shares: Array<Ambita.Share>;
  cadastres: Array<Ambita.Cadastre>;
};

export const addressLookup = async (
  address: string,
  page: number = 1,
  pageSize: number = 100
): Promise<Array<Ambita.Address>> => {
  return (
    await client.get<Array<Ambita.Address>>(
      `/ambita/addresses/search?q=${address}&page=${page}&pageSize=${pageSize}`
    )
  ).data;
};

export const getUnitsForAddress = async (
  addressKey: string,
  page: number = 1,
  pageSize: number = 100
): Promise<UnitsApiResponse> => {
  return (
    await client.get<UnitsApiResponse>(
      `/ambita/addresses/${addressKey}/units?page=${page}&pageSize=${pageSize}`
    )
  ).data;
};

export const getOwners = async (params: { shareId?: string; cadastreKey?: string }) => {
  return (
    await client.get<Array<{ name: string }>>(
      `/ambita/${params.shareId ? 'shares' : 'cadastres'}/${
        params.shareId || params.cadastreKey
      }/owners`
    )
  ).data;
};
